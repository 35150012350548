import { SignedIn, UserButton } from '@clerk/remix'
import { Link } from '@remix-run/react'
import { DiscordLink } from '~/components/social-links'
import { Button } from './ui/button'

type NavbarProps = {
  isAdmin: boolean
  isStorybook?: boolean
}

export function Navbar({ isAdmin, isStorybook = false }: NavbarProps) {
  const SignedInWrapper = isStorybook ? ({ children }) => children : SignedIn
  return (
    <nav className="flex w-full items-center justify-between bg-white p-2 shadow-md">
      <div className="flex items-center">
        <Link
          to="/"
          prefetch={isStorybook ? 'none' : 'intent'}
          className="flex items-center justify-center space-x-1"
        >
          <div className="">
            <img src="/solidten-text.svg" alt="SolidTen Logo" className="h-8 px-2 py-1 sm:px-4" />
            {/* <p className="text-sm text-gray-500 font-semilight hidden sm:block">
        Personalized AI art as easy as taking a selfie
      </p> */}
          </div>
        </Link>
      </div>
      <div className="ml-auto flex items-center">
        <DiscordLink />
        {isAdmin && (
          <SignedInWrapper>
            <Button variant={'link'} asChild>
              <Link to="/dashboard" prefetch="intent">
                Dashboard
              </Link>
            </Button>
          </SignedInWrapper>
        )}

        <Button variant={'link'} asChild>
          <Link to="/create" prefetch="intent">
            Create Art
          </Link>
        </Button>

        {/* auth */}
        <SignedInWrapper>
          {isStorybook ? (
            <div className="h-8 w-8 rounded-full bg-gray-400"></div>
          ) : (
            <UserButton afterSignOutUrl="." />
          )}
        </SignedInWrapper>
      </div>
    </nav>
  )
}
