import { useEffect } from 'react'
import { DiscordLink, TwitterLink } from './social-links'

/**
 * Renders footer with privacy control. Avoid destroying and re-rendering this component as it will
 * cause the privacy buttons to stop working. Iubenda only searches for the privacy buttons once.
 * If you need to remove the footer, consider using CSS to hide it instead.
 */
export function SolidTenFooter() {
  usePrivacyControl()

  // Iubenda script for privacy policy and cookie policy links
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.iubenda.com/iubenda.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      // Check if the script is still a child of body before removing it
      if (document.body.contains(script)) {
        document.body.removeChild(script)
      }
    }
  }, [])

  return (
    <footer className="w-full bg-gray-100 py-4">
      <div className="container flex flex-col items-center justify-center space-y-1 sm:flex-row sm:space-x-4 sm:space-y-0">
        <div className="flex items-center space-x-2">
          <p className="text-sm">© {new Date().getFullYear()} solidten.ai</p>
          {/* <CopyrightIcon className="w-4 h-4" /> */}
        </div>
        <div>
          <a
            href="https://www.iubenda.com/privacy-policy/64403798"
            className="iubenda-white iubenda-noiframe iubenda-embed"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a
            href="https://www.iubenda.com/privacy-policy/64403798/cookie-policy"
            className="iubenda-white iubenda-noiframe iubenda-embed"
            title="Cookie Policy "
          >
            Cookie Policy
          </a>
        </div>
        <div>
          <button className="iubenda-cs-preferences-link flex items-center space-x-1 text-sm underline-offset-2 hover:underline">
            <img
              className="w-8"
              src="data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 14' style='enable-background:new 0 0 30 14;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7d .st1%7bfill-rule:evenodd;clip-rule:evenodd;fill:%230066FF;%7d .st2%7bfill:%23FFFFFF;%7d .st3%7bfill:%230066FF;%7d %3c/style%3e%3cg%3e%3cg id='final---dec.11-2020_1_'%3e%3cg id='_x30_208-our-toggle_2_' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2_2_' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st0' d='M7.4,12.8h6.8l3.1-11.6H7.4C4.2,1.2,1.6,3.8,1.6,7S4.2,12.8,7.4,12.8z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3cg id='final---dec.11-2020'%3e%3cg id='_x30_208-our-toggle' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st1' d='M22.6,0H7.4c-3.9,0-7,3.1-7,7s3.1,7,7,7h15.2c3.9,0,7-3.1,7-7S26.4,0,22.6,0z M1.6,7c0-3.2,2.6-5.8,5.8-5.8 h9.9l-3.1,11.6H7.4C4.2,12.8,1.6,10.2,1.6,7z'/%3e%3cpath id='x' class='st2' d='M24.6,4c0.2,0.2,0.2,0.6,0,0.8l0,0L22.5,7l2.2,2.2c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0 l0,0l-2.2-2.2L19.5,10c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l0,0L20.8,7l-2.2-2.2c-0.2-0.2-0.2-0.6,0-0.8 c0.2-0.2,0.6-0.2,0.8,0l0,0l2.2,2.2L23.8,4C24,3.8,24.4,3.8,24.6,4z'/%3e%3cpath id='y' class='st3' d='M12.7,4.1c0.2,0.2,0.3,0.6,0.1,0.8l0,0L8.6,9.8C8.5,9.9,8.4,10,8.3,10c-0.2,0.1-0.5,0.1-0.7-0.1l0,0 L5.4,7.7c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l0,0L8,8.6l3.8-4.5C12,3.9,12.4,3.9,12.7,4.1z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
              alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"
            />
            <span>Your Privacy Choices</span>
          </button>
        </div>
        <div>
          <button className="iubenda-cs-uspr-link text-sm underline-offset-2 hover:underline">
            Notice at Collection
          </button>
        </div>

        <div className="flex items-center space-x-4">
          <DiscordLink size={24} />
          <TwitterLink size={24} />
          {/* <Button className="text-sm" variant="icon">
            <Link className="underline underline-offset-2" to="#">
              <TwitterIcon className="w-4 h-4" />
            </Link>
          </Button>
          <Button className="text-sm" variant="icon">
            <Link className="underline underline-offset-2" to="#">
              <DiscIcon className="w-4 h-4" />
            </Link>
          </Button> */}
        </div>
      </div>
    </footer>
  )
}

/**
 * Privacy Controls and Cookie Solution.
 * - This is a React Hook that will inject the Iubenda scripts into the DOM.
 * - It displays the cookie banner and handling blocking cookies if the user
 *  does not consent.
 * - It displays "Your Privacy Choices" and "Notice at Collection" buttons at the bottom of the page.
 * - You can replace the buttons with your own custom buttons by using
 *  the `iubenda-cs-preferences-link` and `iubenda-cs-uspr-link` classes.
 */
function usePrivacyControl() {
  useEffect(() => {
    // Iubenda configuration script
    const iubendaConfigScript = document.createElement('script')
    iubendaConfigScript.type = 'text/javascript'
    iubendaConfigScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        "askConsentAtCookiePolicyUpdate": true,
        "enableFadp": true,
        "enableLgpd": true,
        "enableUspr": true,
        "fadpApplies": true,
        "lang": "en",
        "perPurposeConsent": true,
        "siteId": 3411496,
        "usprApplies": true,
        "whitelabel": false,
        "cookiePolicyId": 64403798,
        "banner": {
          "acceptButtonCaptionColor": "#FFFFFF",
          "acceptButtonColor": "#0073CE",
          "acceptButtonDisplay": true,
          "backgroundColor": "#FFFFFF",
          "closeButtonDisplay": false,
          "customizeButtonCaptionColor": "#4D4D4D",
          "customizeButtonColor": "#DADADA",
          "customizeButtonDisplay": true,
          "explicitWithdrawal": true,
          "listPurposes": true,
          "position": "float-top-center",
          "rejectButtonCaptionColor": "#FFFFFF",
          "rejectButtonColor": "#0073CE",
          "rejectButtonDisplay": true,
          "showTitle": false,
          "textColor": "#000000"
        }
      };
    `
    document.head.appendChild(iubendaConfigScript)

    // Iubenda autoblocking script
    const iubendaAutoblockingScript = document.createElement('script')
    iubendaAutoblockingScript.type = 'text/javascript'
    iubendaAutoblockingScript.src = 'https://cs.iubenda.com/autoblocking/3411496.js'
    document.head.appendChild(iubendaAutoblockingScript)

    // Iubenda stub script
    const iubendaStubScript = document.createElement('script')
    iubendaStubScript.type = 'text/javascript'
    iubendaStubScript.src = '//cdn.iubenda.com/cs/gpp/stub.js'
    document.head.appendChild(iubendaStubScript)

    // Iubenda main script
    const iubendaMainScript = document.createElement('script')
    iubendaMainScript.type = 'text/javascript'
    iubendaMainScript.src = '//cdn.iubenda.com/cs/iubenda_cs.js'
    // iubendaMainScript.charset = 'UTF-8';
    iubendaMainScript.async = true
    document.head.appendChild(iubendaMainScript)

    return () => {
      // Cleanup the scripts when the component unmounts
      if (document.head.contains(iubendaConfigScript)) {
        document.head.removeChild(iubendaConfigScript)
      }
      if (document.head.contains(iubendaAutoblockingScript)) {
        document.head.removeChild(iubendaAutoblockingScript)
      }
      if (document.head.contains(iubendaStubScript)) {
        document.head.removeChild(iubendaStubScript)
      }
      if (document.head.contains(iubendaMainScript)) {
        document.head.removeChild(iubendaMainScript)
      }
    }
  }, [])
}
