import { getAuth } from '@clerk/remix/ssr.server'
import { Outlet, useLoaderData } from '@remix-run/react'
import type { LoaderFunctionArgs, MetaFunction } from '@vercel/remix'
import { useEffect, useRef } from 'react'
import { Navbar } from '~/components/Navbar'
import { SolidTenFooter } from '~/components/SolidTenFooter'
import { Toaster } from '~/components/ui/sonner'
import { isAdmin } from '~/lib/third-party/clerk.server'

export const meta: MetaFunction = () => {
  return [
    { title: 'Create personalized AI art as easy as taking a selfie - SolidTen' },
    {
      name: 'description',
      content:
        "We don't need 30 of your childhood pictures to train our AI. Upload one pic of yourself to create personalized AI art, in seconds, without paying a dime.",
    },
  ]
}

export async function loader(args: LoaderFunctionArgs) {
  const auth = await getAuth(args)
  return {
    isAdmin: isAdmin(auth.userId),
  }
}

export default function AppLayoutRoute() {
  const { isAdmin } = useLoaderData<typeof loader>()

  const toastShown = useRef(false)
  useEffect(() => {
    if (!toastShown.current) {
      // toast.warning(
      //   'We are upgrading our database. Server might be down for 10-20 minutes. Sorry for the inconvenience.',
      //   { duration: 5000, position: 'top-center' },
      // )
      toastShown.current = true
    }
  }, [])

  return (
    <>
      <Navbar isAdmin={isAdmin} />
      <Outlet />
      <SolidTenFooter />
      <Toaster richColors position="bottom-center" />
    </>
  )
}
